import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

const ButtonApp = styled.a`
  padding: 0 9px;
  transition: 0.4s;
  &:visited {
  text-decoration: none;
  }
  &:hover {
  transform: translateY(-8px);
  }
`;

const PlayButton = ({buttonClass}) => {
  return (
    <ButtonApp className={buttonClass} target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.minidukaan.android">
      <StaticImage
        src="../../assets/image/png/google-play.png"
        alt="MiniDukan Play Store"
        className="img-fluid"
      />
    </ButtonApp>

  );
};

export default PlayButton;
