import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { StaticImage } from "gatsby-plugin-image";

import PlayButton from "../../components/PlayButton/PlayButton";
/* import GlobalContext from "../../context/GlobalContext"; */
import { device } from "../../utils";

const SiteHeader = styled.header`
  padding: 10px 0 10px 0;
  position: absolute !important;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  @media ${device.xsm} {
  position: fixed !important;
  transition: 0.4s;
  &.scrolling {
  transform: translateY(-100%);
  transition: 0.4s;
  }
  &.reveal-header {
  transform: translateY(0%);
  box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
  z-index: 9999;
  background: ${({ dark, theme }) =>
  dark ? theme.colors.dark : theme.colors.light};
  }
  }

  /* Bounce To Left */
  .hvr-bounce-to-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  z-index: 1;
  }

  .hvr-bounce-to-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #F9544B;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  }

  // .hvr-bounce-to-left:hover, .hvr-bounce-to-left:focus, .hvr-bounce-to-left:active {
  //   // color: #000 !important;
  // }

  .hvr-bounce-to-left:hover:before, .hvr-bounce-to-left:focus:before, .hvr-bounce-to-left:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }
  .btn-red{
  font-size: 16px !important;
  width: 141px !important;
  height: 45px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #c31a12;
  background: #f04037;
  color: #fff!important;
  font-family: "CircularStd", sans-serif;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  }
`;

const Header = ({ isDark = false }) => {
  /* const gContext = useContext(GlobalContext); */
  const [showScrolling, setShowScrolling] = useState(false);
  const [showReveal, setShowReveal] = useState(false);
  const [buttonReveal, setButtonReveal] = useState(false)

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
    if (currPos.y < -300) {
      setShowReveal(true);
    } else {
      setShowReveal(false);
    }
    if (currPos.y < -500) {
      setButtonReveal(true);
    } else {
      setButtonReveal(false);
    }
  });

  return (
    <>
      <SiteHeader
        className={`sticky-header ${showScrolling ? "scrolling" : ""} ${
          showReveal ? "reveal-header" : ""
        }`}
        dark={isDark ? 1 : 0}
      >
        <Container fluid>
          <nav className="navbar site-navbar navbar-expand-lg navbar-light">
            {/* <!-- Brand Logo--> */}
            <div className={`brand-logo ${showReveal ? "small-logo-scroll" : ""}`} >
              <StaticImage alt="MiniDukan Logo" src="../../assets/image/png/MiniDukanLogo.png"/>
            </div>
            <div
              className={`header-btns ml-auto float-right d-md-block hide-button-animate ${buttonReveal ? "" : "hide-button"}`}
            >
             {/* <ButtonApp href="" className="float-right">
                  <img src={imgPlay} alt="" className="img-fluid" />
                  </ButtonApp> */}
              <PlayButton buttonClass={`float-right`} />
            </div>
          </nav>
        </Container>
      </SiteHeader>
    </>
  );
};
export default Header;
